import React, { Component } from "react";
import jMann from "../imgs/logo.png";

class Logo extends Component {
  render() {
    return (
      <div>
        <center>
          <img src={jMann} width="65%" style={{ marginBottom: "5px" }} />
        </center>
      </div>
    );
  }
}

export default Logo;
